import './assets/css/style.css';
import '@style/App.css';
import '@style/Global.css';
import '@style/MultiSelect.css';
import '@style/Toastify.css';
import '@util/i18n';
import 'react-toastify/dist/ReactToastify.min.css';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import StateProvider from './state';
import loadable from '@loadable/component';
import packageJSON from '@packageJson';

const App = loadable(() => import('./App'));
const Meta = loadable(() => import('@com/Meta'));
const ScrollToTop = loadable(() => import('@com/ScrollToTop'));

const {
  REACT_APP_TITLE,
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV,
} = process.env;
const isDevelopment = NODE_ENV === 'development';

// Sentry
if (!isDevelopment && REACT_APP_SENTRY_DSN)
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: `${packageJSON.name}@${packageJSON.version}`,
    tracesSampleRate: 0.25, // For monitoring performance
    environment: REACT_APP_SENTRY_ENV,
    beforeSend(event) {
      // Skip sending error event if just denied message signature
      try {
        if (
          JSON.stringify(event).match(
            'MetaMask Message Signature: User denied message signature.'
          )
        ) {
          return null;
        }
      } catch (error) {
        return event;
      }
      return event;
    },
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 2,
      retryDelay: 5 * 1000,
      refetchOnMount: true,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <Meta title={REACT_APP_TITLE} />
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StateProvider>
          <ToastContainer
            closeOnClick
            draggable
            pauseOnHover
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss={false}
            position="top-right"
            rtl={false}
            theme="dark"
          />
          <ScrollToTop />
          <App />
        </StateProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>
);
