import { ReactElement } from 'react';
import { cache, market, user, web3 } from './global';

/**
 * State providing wrapper
 * @param {ReactElement | ReactElement[]} children to inject
 * @returns {ReactElement} wrapper
 */
export default function StateProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement {
  return (
    <user.Provider>
      <web3.Provider>
        <market.Provider>
          <cache.Provider>{children}</cache.Provider>
        </market.Provider>
      </web3.Provider>
    </user.Provider>
  );
}

export * from './private';
