import { PaymentEventType, PaymentStatus } from '@share/enums';
import { useHelper } from './useHelper';
import { useTranslation } from 'react-i18next';
import { web3 } from '@state/web3';
import Badge, { BadgeType } from '@com/utils/Badge';
import Spinner from '@com/utils/Spinner';

interface BadgeData {
  text: string;
  badgeType: BadgeType;
}

const useReceipt = () => {
  const { t, i18n } = useTranslation();
  const { transactionExplorerBaseURL } = web3.useContainer();
  const { displayCurrency } = useHelper();
  const badgeData: Record<any, BadgeData> = {
    [PaymentStatus.CAPTURED]: {
      text: t('receipt.paySucceed'),
      badgeType: BadgeType.SUCCESS,
    },
    [PaymentStatus.AUTHORIZED]: {
      text: t('receipt.payAuthorized'),
      badgeType: BadgeType.SUCCESS,
    },
    [PaymentStatus.PENDING]: {
      text: t('receipt.payPending'),
      badgeType: BadgeType.WARNING,
    },
    [PaymentStatus.DELINED]: {
      text: t('receipt.payFailed'),
      badgeType: BadgeType.DANGER,
    },
    [PaymentStatus.VOIDED]: {
      text: t('receipt.payVoided'),
      badgeType: BadgeType.SECONDARY,
    },
  };

  const getBadge = (
    status?: PaymentStatus,
    size: 'sm' | 'md' | 'lg' = 'md'
  ) => {
    if (!status) return '-';
    return (
      <Badge
        size={size}
        text={badgeData[status]?.text || t('receipt.payFailed')}
        type={badgeData[status]?.badgeType || BadgeType.DANGER}
      />
    );
  };

  const getTxURL = (
    chainId?: number,
    hash?: string,
    status?: PaymentStatus,
    event?: string
  ) => {
    // For bidding, no transaction hash, so just return '-'
    if (
      status === PaymentStatus.AUTHORIZED &&
      event === PaymentEventType.BID_NFT
    )
      return '-';

    if (status === PaymentStatus.PENDING) return <Spinner />;

    if (status === PaymentStatus.AUTHORIZED && !hash) return <Spinner />;

    return hash ? (
      <a
        href={`${transactionExplorerBaseURL(
          chainId || Number(process.env.REACT_APP_DEFAULT_CHAINID)
        )}/${hash}`}
        rel="noreferrer"
        target="_blank"
      >
        {hash}
      </a>
    ) : (
      '-'
    );
  };

  const getCardNumber = (last4: string) => {
    return last4 ? `**** **** **** ${last4}` : '-';
  };

  const getAmount = (amount?: number, curr?: string) => {
    return amount && curr ? displayCurrency(Number(amount), curr) : '-';
  };

  const getInvoiceDate = (timestamp?: number) => {
    return timestamp
      ? new Date(Number(timestamp) * 1000).toLocaleString(i18n.language)
      : '-';
  };

  return {
    getBadge,
    getTxURL,
    getCardNumber,
    getAmount,
    getInvoiceDate,
  };
};

export { useReceipt };
