import { LANGUAGE } from '@share/constant';
import { LanguageType } from '@share/enums';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import i18n, { InitOptions } from 'i18next';

interface LanguageItem {
  key: LanguageType;
  name: string;
}

export const LanguageList: LanguageItem[] = [
  {
    key: LanguageType.EN,
    name: 'English',
  },
  {
    key: LanguageType.ZH_TW,
    name: '繁體中文',
  },
  {
    key: LanguageType.ZH_CN,
    name: '简体中文',
  },
];

export const I18nConfig: InitOptions = {
  lng: LanguageType.EN,
  fallbackLng: LanguageType.EN,
  supportedLngs: LanguageList.map((item) => item.key),
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    // Turn off the use of React Suspense
    useSuspense: false,
  },
};

i18n.on('initialized', async () => {
  try {
    await i18n.changeLanguage(
      JSON.parse(localStorage.getItem(LANGUAGE) || '') || I18nConfig.lng
    );
  } catch (error) {
    //
  }
});

i18n.use(initReactI18next).use(HttpApi).init(I18nConfig);

export default i18n;
