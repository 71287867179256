import { createContainer } from 'unstated-next';
import { useState } from 'react';

function useCache() {
  const [cacheImgList, setCacheImgList] = useState<Record<string, boolean>>({});

  /**
   * This function is to prevent re-render skeleton if image already cached by broswer
   */
  const addCacheImg = (key: string, status = true) => {
    if (cacheImgList[key] == null)
      setCacheImgList((prev) => ({ ...prev, [key]: status }));
  };

  return { cacheImgList, addCacheImg };
}

export const cache = createContainer(useCache);
