import { useAPI } from './useAPI';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { web3 } from '@state/web3';

const useSystemConfig = () => {
  const { getConfig } = useAPI(false);
  const { _paramsChainId } = useParams();
  const { chainId } = web3.useContainer();
  const queryChainId = Number(_paramsChainId) || chainId;
  const { data } = useQuery(
    [getConfig.name, queryChainId],
    () => getConfig(queryChainId),
    {
      refetchOnMount: false,
    }
  );

  return { config: data };
};

export { useSystemConfig };
