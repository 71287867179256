import { CheckoutPaymentData } from '@share/interfaces';
import { createContainer } from 'unstated-next';
import { useState } from 'react';

function usePayment() {
  const [quantity, setQuantity] = useState(1);
  const [paymentModalData, setPaymentModalData] =
    useState<CheckoutPaymentData>();

  return { quantity, paymentModalData, setQuantity, setPaymentModalData };
}

export const payment = createContainer(usePayment);
