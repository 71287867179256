import { css } from '@emotion/react';

export enum BadgeType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  SECONDARY = 'secondary',
}

interface Props {
  text?: string;
  type: BadgeType;
  size: 'sm' | 'md' | 'lg';
}

function Badge(props: Props): JSX.Element {
  const { text, type, size } = props;
  return (
    <div
      css={css`
        display: inline-block;
        font-weight: bold;
        background-color: var(--primary-color5);
        border-radius: 0.5em;
        color: var(--primary-color3);
        ${size === 'lg' &&
        css`
          padding: 0.3em 0.6em;
          font-size: 1.5em;
        `}
        ${size === 'md' &&
        css`
          padding: 0.1em 1.2em;
          font-size: 0.8em;
        `}
        ${size === 'sm' &&
        css`
          padding: 0.1em 0.3em;
          font-size: 0.8em;
        `}
      `}
    >
      <span className={`text-${type}`}>{text}</span>
    </div>
  );
}

export default Badge;
