import * as Sentry from '@sentry/react';
import { I18nConfig } from '@util/i18n';
import { LANGUAGE, THEME, USER_DATA } from '@share/constant';
import { LanguageType, ThemeType } from '@share/enums';
import { LoginData } from '@share/interfaces';
import { createContainer } from 'unstated-next';
import { defaultTheme } from '@asset/themes/config';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

function useUser() {
  const [persistUserData, setPersistUserData] =
    useLocalStorage<LoginData | null>(USER_DATA, null);
  const [persistTheme, setPersistTheme] = useLocalStorage<ThemeType>(
    THEME,
    defaultTheme
  );
  const [persistLanguage, setPersistLanguage] = useLocalStorage<LanguageType>(
    LANGUAGE,
    I18nConfig.lng as LanguageType
  );

  const [userData, setUserData] = useState<LoginData | null>(persistUserData);
  const [theme, _setTheme] = useState<ThemeType>(persistTheme);
  const [language, _setLanguage] = useState(persistLanguage);

  const [isLogin, setIsLogin] = useState<boolean | null>(null);
  const [isNoMetamask, setIsNoMetamask] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const setTheme = (type: ThemeType) => {
    _setTheme(type);
    setPersistTheme(type);
  };

  const setLanguage = (lang: LanguageType) => {
    _setLanguage(lang);
    setPersistLanguage(lang);
    i18n.changeLanguage(lang);
    dayjs.locale(lang?.toLocaleLowerCase());
  };

  const setUser = (data: LoginData | null) => {
    setPersistUserData(data);
    setUserData(data);

    const lang = data?.user?.language;
    if (lang) setLanguage(lang);
  };

  const getMetamaskStatus = (_userData?: LoginData) => {
    // Check the default wallet is auto generated or not
    const finalUserData = _userData || userData;
    if (
      finalUserData?.user?.wallet?.find(
        (w) =>
          w.wallet_address === finalUserData.user.wallet?.[0].wallet_address
      )?.is_treasury
    )
      return true;

    return false;
  };

  const updateMetamaskStatus = (_userData: LoginData) => {
    setIsNoMetamask(getMetamaskStatus(_userData));
  };

  useEffect(() => {
    try {
      if (userData) {
        setIsLogin(true);
        updateMetamaskStatus(userData);
      } else setIsLogin(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useEffect(() => {
    if (i18n.language) setLanguage(i18n.language as LanguageType);
  }, [i18n.language]);

  return {
    userData,
    theme,
    language,
    isLogin,
    isNoMetamask,
    setIsLogin,
    updateMetamaskStatus,
    setUser,
    setTheme,
    setLanguage,
  };
}

export const user = createContainer(useUser);
