import { Currency, SimplifiedPaymentMethod } from '@share/interfaces';
import { PaymentGatewayType } from '@share/enums';
import { createContainer } from 'unstated-next';
import { useEffect, useMemo, useState } from 'react';
import { useSystemConfig } from 'src/hooks';
import { user } from '@state/user';

function useListing() {
  const { config } = useSystemConfig();
  const { isNoMetamask } = user.useContainer();

  const [paymentMethods, setPaymentMethods] = useState<
    SimplifiedPaymentMethod[]
  >([]);

  // Handling one payment gateway case
  useEffect(() => {
    if (isNoMetamask != null) {
      setPaymentMethods(
        config?.payment_gateways?.filter((gateway) => {
          return isNoMetamask
            ? gateway.payment_gateway_type !== PaymentGatewayType.CRYPTOCURRENCY
            : gateway.payment_gateway_type ===
                PaymentGatewayType.CRYPTOCURRENCY;
        })?.[0]?.methods || []
      );
    }
  }, [isNoMetamask]);

  const allowedCurrencies = useMemo(() => {
    const filteredPaymentMethods = paymentMethods
      .map((method) =>
        config?.currencies.find((c) => c.id === method.currency_id)
      )
      .filter((c) => !!c) as Currency[];

    return filteredPaymentMethods.sort((a, b) =>
      a?.name.localeCompare(b?.name)
    );
  }, [paymentMethods]);

  const getServiceRate = useMemo(() => {
    return (currencyId: number) =>
      paymentMethods.find((c) => c.currency_id === currencyId)
        ?.marketplace_fee || 0;
  }, [paymentMethods]);

  const getHandlingRate = useMemo(() => {
    return (currencyId: number) =>
      paymentMethods.find((c) => c.currency_id === currencyId)?.payment_fee ||
      0;
  }, [paymentMethods]);

  const getMethodId = useMemo(() => {
    return (currencyId: number) =>
      paymentMethods.find((c) => c.currency_id === currencyId)?.uuid || '';
  }, [paymentMethods]);

  return {
    allowedCurrencies,
    getServiceRate,
    getHandlingRate,
    getMethodId,
    setPaymentMethods,
  };
}

export const listing = createContainer(useListing);
