import { SVGProps } from 'react';
import styled from '@emotion/styled';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  width?: number;
  height?: number;
}

function Spinner(props: Props): JSX.Element {
  /* Define state / hook */
  const { color = 'var(--primary-color3)', width = 24, height = 24 } = props;

  /* Custom Data Object for render or other usages */

  /* Render control function / logic */

  /* Data Change Listeners - useEffect/useMemo/useCallback */

  /* Styling */
  const StyledSVG = styled.svg`
    background: transparent;
    display: block;
    shape-rendering: auto;

    circle {
      fill: ${color};
    }
  `;

  /* Render */
  return (
    <StyledSVG
      className={props.className}
      height={`${height}px`}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width={`${width}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(80,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" fillOpacity="1" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.875s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.875s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,71.21320343559643)">
        <g transform="rotate(45)">
          <circle cx="0" cy="0" fillOpacity="0.875" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.75s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.75s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(50,80)">
        <g transform="rotate(90)">
          <circle cx="0" cy="0" fillOpacity="0.75" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.625s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.625s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(28.786796564403577,71.21320343559643)">
        <g transform="rotate(135)">
          <circle cx="0" cy="0" fillOpacity="0.625" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.5s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.5s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(20,50.00000000000001)">
        <g transform="rotate(180)">
          <circle cx="0" cy="0" fillOpacity="0.5" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.375s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.375s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(28.78679656440357,28.786796564403577)">
        <g transform="rotate(225)">
          <circle cx="0" cy="0" fillOpacity="0.375" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.25s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.25s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(49.99999999999999,20)">
        <g transform="rotate(270)">
          <circle cx="0" cy="0" fillOpacity="0.25" r="6">
            <animateTransform
              attributeName="transform"
              begin="-0.125s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="-0.125s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,28.78679656440357)">
        <g transform="rotate(315)">
          <circle cx="0" cy="0" fillOpacity="0.125" r="6">
            <animateTransform
              attributeName="transform"
              begin="0s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="scale"
              values="1.5 1.5;1 1"
            />
            <animate
              attributeName="fill-opacity"
              begin="0s"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              values="1;0"
            />
          </circle>
        </g>
      </g>
    </StyledSVG>
  );
}

export default Spinner;
