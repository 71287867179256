import * as Sentry from '@sentry/react';
import { API_ERROR_MSG, USER_DATA } from '@share/constant';
import { BackendResponse, LoginData } from '@share/interfaces';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import axios, { AxiosError, AxiosResponse } from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;
const client = axios.create({
  baseURL: `${REACT_APP_API_BASE_URL}/api/v1`,
});

client.interceptors.request.use((config) => {
  let userData: any;
  if (!userData) {
    try {
      userData = JSON.parse(localStorage.getItem(USER_DATA) || '{}');
    } catch (error) {
      userData = {};
      Sentry.captureException(error);
    }
  }

  const { headers } = config;
  if (headers && userData.token)
    headers.Authorization = `Bearer ${userData.token}`;
  return config;
});

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const errorCode = error.response?.status;
    let errorMsg = '';
    if (errorCode === 401) {
      errorMsg = t('error.401');
    } else if (errorCode === 404) {
      errorMsg = t('error.404');
    } else if (errorCode === 422) {
      errorMsg = t('error.422');
    } else if (errorCode === 500) {
      errorMsg = t('error.500');
    } else if (errorCode === 504) {
      errorMsg = t('error.504');
    } else if ((error.response?.data as any)?.message || error.message) {
      errorMsg = (error.response?.data as any)?.message || error.message;
    }
    if (!toast.isActive(API_ERROR_MSG) && errorMsg) {
      // only show one popup at the same time
      toast.error(errorMsg, { toastId: API_ERROR_MSG });
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  }
);

export enum APIName {
  // TODO: refactor web3.ts
  POST_GET_NONCE = 'POST_GET_NONCE',
  POST_CONNECT_WALLET = 'POST_CONNECT_WALLET',
  LOGOUT = 'LOGOUT',
  GET_LIST_NETWORK = 'GET_LIST_NETWORK',
}

const Action = {
  [APIName.GET_LIST_NETWORK]: async (is_active = Number(true)) => {
    const { data } = await client.get<any>('/network', {
      params: { is_active },
    });
    return data.data;
  },
  [APIName.LOGOUT]: async () => {
    try {
      client.post('/auth/logout');
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  [APIName.POST_CONNECT_WALLET]: async (
    address: string,
    signature: string,
    nonce: string
  ) => {
    const { data } = await client.post<BackendResponse<LoginData>>(
      '/auth/connect_wallet',
      {
        address,
        signature,
        nonce,
      }
    );
    return data.data;
  },
  [APIName.POST_GET_NONCE]: async (address: string, nonce: string) => {
    const { data } = await client.post<any>('/auth/get_nonce', {
      address,
      nonce,
    });
    return data.data;
  },
};

export const Fun: { [key in APIName]: (typeof Action)[key] } = Action;
