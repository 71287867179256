export const CONNECTED = 'WEB3_CONNECT_CACHED_PROVIDER';
export const USER_DATA = 'USER_DATA';
export const CHAIN_ID = 'CHAIN_ID';
export const THEME = 'THEME';
export const LANGUAGE = 'LANGUAGE';
export const API_ERROR_MSG = 'API_ERROR_MSG';
export const LEGAL_TENDER = 'LEGAL_TENDER';
export const TREASURY_ACTION = 'TREASURY_ACTION';
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const ACTION_REJECTED = 'ACTION_REJECTED';
